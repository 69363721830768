import styled from '@emotion/styled'
import { useState,useEffect } from 'react'
import { GoogleLogin } from 'react-google-login';
import {updateCuentasClient} from '../../client/actions'
import {useDispatch, useSelector} from 'react-redux';
//Estilos del contenedor 
const DIV = styled.div`
        width: 27%;
        height: 320px;
        margin: 9px 10px 9px 15px;
        padding: 39px 57px 55px 76px;
        border-radius: 10px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        float: left;

        @media (max-width:500px){
          width: 67%
        }
    `
//Estilos del boton de Google
const CONT = styled.div`
        width: 345px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin: 0 47px 44px 0;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
        background-color: #fff;

        @media (max-width:500px){
          margin: 0px 0px 0px -40px;
          } 
        
        &:hover {
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 4px 4px 8px rgba(0, 0, 0, 0.168);
          cursor: pointer;
        }

        & > span {
         flex-grow: 0;
         font-family: Helvetica;
         font-size: 20px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         text-align: left;
         color: rgba(0, 0, 0, 0.54);

         & > img {
          width: 30px;
          height: 30px;
          margin: -5px 15px -7px 0px;     
                }
        & > button{
          box-shadow:none;
          font-size:20px;
        }

        @media (max-width:500px){
          margin: 0px 0px 0px 30px;
          }

        }

  `

  const TEXTDIV = styled.p`
flex-grow: 0;

font-family: Roboto;
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
margin-left: 10px;

& > span {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba
}

& > img {
  width: 15px;
  height: 15px;

}

`

const ContenedorGoogle = ({cuentas}) => {

  const dispatch = useDispatch();
  const client = useSelector(state=> state.client);

  const responseGoogle = (response) => {
    //secreto: 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
    if (!response.hasOwnProperty("error")){
      fetch(`${process.env.REACT_APP_API_URL}/api/updateTokenGoogle` , {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
        },
        body: JSON.stringify({
          'idGoogle': response.googleId,
          'token':  JSON.stringify(response.tokenObj),
          'cuenta_nombre': response.profileObj.givenName,
          'cuenta_id': response.profileObj.googleId
        })
      })
      .then(response => response.json())
      .then(respuesta =>{
        if (respuesta.hasOwnProperty("cuentas")){
          dispatch(updateCuentasClient(respuesta.cuentas));
        }
      })
      .catch((error) => { 
        console.log(error);
        throw error })
    }
  }
     
        
  return (
        <DIV>
        <CONT>
        <GoogleLogin
          clientId="636167056781-htbivhdbbh7s7ljfqomoj6bi1gv2toea.apps.googleusercontent.com"
          buttonText="Iniciar sesion con Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          isSignedIn={false}
          scope="https://www.googleapis.com/auth/youtube"
          className="btn_google_connect"
        />
        </CONT>
        {cuentas && cuentas.hasOwnProperty('google') && cuentas.google?.map ((cuenta) => cuenta.activa ?
            <TEXTDIV>
              <img className="verticalAlign mr-10" src="/assets/images/checkGreen.png" />
              <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
            </TEXTDIV>
          : 
            <TEXTDIV>
              <img className="verticalAlign mr-10" src="/assets/images/checkRed.png" />
              <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
            </TEXTDIV>
          )}

    </DIV>
  )
}

export default ContenedorGoogle