/* *************** CAMPAIGN_INFLUENCER *************** */
export const SINGLE_CAMPAIGN_INFLUENCER_REQUESTING = 'SINGLE_CAMPAIGN_INFLUENCER_REQUESTING';
export const SINGLE_CAMPAIGN_INFLUENCER_SUCCESS = 'SINGLE_CAMPAIGN_INFLUENCER_SUCCESS';
export const SINGLE_CAMPAIGN_INFLUENCER_ERROR = 'SINGLE_CAMPAIGN_INFLUENCER_ERROR';
export const DELETE_POST_INFLUENCER_REQUESTING='DELETE_POST_INFLUENCER_REQUESTING';
export const DELETE_POST_INFLUENCER_SUCCESS='DELETE_POST_INFLUENCER_SUCCESS';
export const DELETE_POST_INFLUENCER_ERROR = 'DELETE_POST_INFLUENCER_ERROR';

export const INFLUENCER_DATOS_REQUESTING = 'INFLUENCER_DATOS_REQUESTING';
export const INFLUENCER_DATOS_SUCCESS = 'INFLUENCER_DATOS_SUCCESS';
export const INFLUENCER_DATOS_ERROR = 'INFLUENCER_DATOS_ERROR';



export const INFLUENCER_INFORME_REQUESTING = 'INFLUENCER_INFORME_REQUESTING';
export const INFLUENCER_INFORME_SUCCESS = 'INFLUENCER_INFORME_SUCCESS';
export const INFLUENCER_INFORME_ERROR = 'INFLUENCER_INFORME_ERROR';
/* *************** END CAMPAIGN_INFLUENCER *************** */



/* *************** STORIES *************** */
export const SINGLE_CAMPAIGN_INFLUENCER_STORIES_REQUESTING = 'SINGLE_CAMPAIGN_INFLUENCER_STORIES_REQUESTING';
export const SINGLE_CAMPAIGN_INFLUENCER_STORIES_SUCCESS = 'SINGLE_CAMPAIGN_INFLUENCER_STORIES_SUCCESS';
export const SINGLE_CAMPAIGN_INFLUENCER_STORIES_ERROR = 'SINGLE_CAMPAIGN_INFLUENCER_STORIES_ERROR';
/* *************** END STORIES *************** */


/* *************** FILTROS *************** */
export const FILTROS_APPLY = 'FILTROS_APPLY';
export const FILTROS_APPLY_RESPONSE = 'FILTROS_APPLY_RESPONSE';
export const FILTROS_RESULT_UPDATE_VALUE = 'FILTROS_RESULT_UPDATE_VALUE';

/* *************** FILTROS *************** */
/* *************** GRÁFICAS *************** */
export const DATOS_GRAFICA_SPLINE_INFLUENCER_REQUESTING = 'DATOS_GRAFICA_SPLINE_INFLUENCER_REQUESTING';
export const DATOS_GRAFICA_SPLINE_INFLUENCER_SUCCESS = 'DATOS_GRAFICA_SPLINE_INFLUENCER_SUCCESS';
export const DATOS_GRAFICA_SPLINE_INFLUENCER_ERROR = 'DATOS_GRAFICA_SPLINE_INFLUENCER_ERROR';
export const DATOS_GRAFICA_DONUT_INFLUENCER_REQUESTING = 'DATOS_GRAFICA_DONUT_INFLUENCER_REQUESTING';
export const DATOS_GRAFICA_DONUT_INFLUENCER_SUCCESS = 'DATOS_GRAFICA_DONUT_INFLUENCER_SUCCESS';
export const DATOS_GRAFICA_DONUT_INFLUENCER_ERROR = 'DATOS_GRAFICA_DONUT_INFLUENCER_ERROR';
/* *************** END GRÁFICAS *************** */

export const SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER = 'SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER';
export const SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_ERROR = 'SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_ERROR';
export const SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_END = 'SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_END';
export const SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER = 'SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER';
export const SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER_END = 'SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER_END';
export const SINGLE_CAMPAIGN_INFLUENCER_ADD_HASHTAG = 'SINGLE_CAMPAIGN_INFLUENCER_ADD_HASHTAG';
export const SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST = 'SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST';
export const SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST_STATE = 'SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST_STATE';

