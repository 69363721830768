import React, { useState, useEffect, Fragment } from 'react';
import BarraSuperior from '../componentes/conectar_cuentas/barraSuperior';
import ContenedorFacebook from '../componentes/conectar_cuentas/contenedorFacebook';
import ContenedorGoogle from '../componentes/conectar_cuentas/contenedorGoogle';
import ContenedorInstagram from '../componentes/conectar_cuentas/contenedorInstagram';
import ContenedorLinkedin from '../componentes/conectar_cuentas/contenedorLinkedin';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';

//Estilos de la pagina
const DIV = styled.div`
 
  background-color: red;
  `

const Cuentas = ({}) => {
    
    /* ************ STATES *********** */
    const client = useSelector(state=> state.client);

    useEffect(()=>{
        if (
            client.hasOwnProperty("decode") && 
            client.decode.hasOwnProperty("cuentas_user") 
        ){
            setCuentas(client.decode.cuentas_user);
        }
    },[,client])
    
    const [cuentas, setCuentas] = useState(client.decode.cuentas_user);
    const [render, setRender] = useState(Math.round);
    /* ************ END STATES *********** */

    return (
        <Fragment key={`c_${render}`}>
            <BarraSuperior />
            <ContenedorInstagram
                cuentas = {cuentas}
                setCuentas = {setCuentas}
                
            />
            <ContenedorFacebook 
                cuentas = {cuentas}
                setCuentas = {setCuentas}
            />
            <ContenedorGoogle 
            cuentas = {cuentas}
            setCuentas = {setCuentas}
            /> 
            <ContenedorLinkedin
            cuentas = {cuentas}
            setCuentas = {setCuentas}
            /> 
        </Fragment>
       
    );
};

export default Cuentas;