import {
  SINGLE_CAMPAIGN_INFLUENCER_REQUESTING,
  SINGLE_CAMPAIGN_INFLUENCER_STORIES_REQUESTING,
  DATOS_GRAFICA_SPLINE_INFLUENCER_REQUESTING,
  FILTROS_APPLY,
  DATOS_GRAFICA_DONUT_INFLUENCER_REQUESTING,
  FILTROS_RESULT_UPDATE_VALUE,
  SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER,
  SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER,
  SINGLE_CAMPAIGN_INFLUENCER_ADD_HASHTAG,
  DELETE_POST_INFLUENCER_REQUESTING,
  INFLUENCER_INFORME_REQUESTING,
  INFLUENCER_INFORME_SUCCESS,
  INFLUENCER_INFORME_ERROR
} from './constants'

export function dashboardInfluencer(client, filtro=null, page=null){
 return {
    type: SINGLE_CAMPAIGN_INFLUENCER_REQUESTING,
    payload: {
      client: client,
      filtro: filtro,
      "page": page,
    }
  }
}

export function deletePostInfluencerRequesting(client, idPost){
  return {
    type: DELETE_POST_INFLUENCER_REQUESTING,
    payload: {
      client: client,
      idPost: idPost,
    }
  };
};



 export function singleCampaignStories(client,  page=null, filtro={}){
  return {
   type: SINGLE_CAMPAIGN_INFLUENCER_STORIES_REQUESTING,
   payload: {
     client: client,
     
     "page": page,
     filtro: filtro
   }
  };
};

 

 export function getDataGraficaSpline(client, idPost, filtro={}){
  return {
    type: DATOS_GRAFICA_SPLINE_INFLUENCER_REQUESTING,
    payload: {
      client: client,
      idPost: idPost,
      filtro: filtro
    }
  };
};

export function aplicarFiltros(client,  values){
  return {
     type: FILTROS_APPLY,
     payload: {
       client: client,
       
      values: values
     }
   }
 }
export function getDataGraficaDonut(client, idPost){
  return {
    type: DATOS_GRAFICA_DONUT_INFLUENCER_REQUESTING,
    payload: {
      client: client,
      idPost: idPost,
    }
  };
};

export function updateValorCampRespuestaFiltro(campo,valor){
  return {
    type: FILTROS_RESULT_UPDATE_VALUE,
    payload: {
      campo: campo,
      valor: valor,
    }
  };
};

export function addInfluencer(influencer,  client){
  return {
    type: SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER,
    payload: {
      influencer: influencer,
      client: client
    }
  };
};
export function deleteInfluencer(influencer,  client){
  return {
    type: SINGLE_CAMPAIGN_INFLUENCER_DELETE_INFLUENCER,
    payload: {
      influencer: influencer,
      client: client
    }
  };
};
export function addHashtag(hashtag, client){
  return {
    type: SINGLE_CAMPAIGN_INFLUENCER_ADD_HASHTAG,
    payload: {
      hashtag: hashtag,
      client: client
    }
  };
};


export function getInformeInit(){
  return {
    type: INFLUENCER_INFORME_REQUESTING,
  };
};

export function getInformeFin(){
  return {
    type: INFLUENCER_INFORME_SUCCESS,
  };
};



// Since it's the only one here
export default dashboardInfluencer
