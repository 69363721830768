
export const CLIENT_SET = 'CLIENT_SET'
export const CLIENT_UNSET = 'CLIENT_UNSET'
export const CLIENT_SUPERADO_PLAN = 'CLIENT_SUPERADO_PLAN'
export const CLIENT_SUPERADO_PLAN_CANCEL = 'CLIENT_SUPERADO_PLAN_CANCEL'
export const CLIENT_UPDATE_CUENTAS = 'CLIENT_UPDATE_CUENTAS'
export const CLIENT_UPDATE_PLAN = 'CLIENT_UPDATE_PLAN'
export const CLIENT_UPDATE_VERIFIED = 'CLIENT_UPDATE_VERIFIED'


