import React, { Fragment, useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import CuentaSubscripcionForm from '../componentes/formularios/cuentaSubscripcionForm'
import styled from '@emotion/styled'
import Barrainferior from '../componentes/barrainferior'
import { getAccountDetailsRequest } from '../datoscuenta/actions';

//Estilos de la pagina

const Divup = styled.div`
width: 70%;
margin: 20px 0px 10px 15px;
padding: 0px 28.1px 0px 21.4px;
border-radius: 10px;
box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
background-color: #fff;
float: left;
position: top;

@media (max-width:500px){
  width: 82%;
  }

& > p {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  font-weight: bold;
} 

@media (max-width:500px){
  font-size: 15px;
  padding: 0px 0px 0px 50px;
  }  
`
    

const CuentaSubscripcion = () => {

  const dispatch = useDispatch();
  const client = useSelector((state) => state.client);
  const datosCuenta = useSelector((state) => state.datoscuenta);

  const [userData, setUserData] = useState([]);

  useEffect(()=>{
    dispatch(getAccountDetailsRequest(client));
  },[])

  useEffect(()=>{
    if (datosCuenta.datos && datosCuenta.datos.length > 0){
      setUserData(datosCuenta.datos);
    }
  },[datosCuenta])


  return (
    
    <Fragment>
       <Divup>
        <p>Cuenta y Subscripción</p>
      </Divup>
      
      {userData.length > 0 ? 
      <CuentaSubscripcionForm
        userData = {userData}
      />
      :null}
      
     
      <Barrainferior />
    </Fragment>
    
  )
}

export default CuentaSubscripcion