import React from 'react'
import styled from '@emotion/styled'

const BoxTotalVisiblity = styled.div`
background: #ffffff;
width: 85%;
margin: 10px;


`

const IconTotalVIsibility = styled.img`
  width: 15px;
  height: 15px;
  margin: 0px 0px 0px 15px;
 
`
const SpanTotalVIsibility = styled.span`
  float: right;
  margin: 0px 10px 0px 0px;
`

const HrTotalVisiblity = styled.hr`
  width: 85%;
`

const TotalVisibility = ({facebook, instagram, twitter, youtube, linkedin, style, className, keyAux}) => {
  return (

     
<BoxTotalVisiblity className={className} style={style} key={keyAux}>
    
    <IconTotalVIsibility src="/assets/images/instagram-logo.png"/><SpanTotalVIsibility>{instagram}</SpanTotalVIsibility>
    <HrTotalVisiblity></HrTotalVisiblity>
    <IconTotalVIsibility src="/assets/images/twitter-logo.png"/><SpanTotalVIsibility>{twitter}</SpanTotalVIsibility>
    <HrTotalVisiblity></HrTotalVisiblity>
    <IconTotalVIsibility src="/assets/images/fb-logo.png"/><SpanTotalVIsibility>{facebook}</SpanTotalVIsibility>
    <HrTotalVisiblity></HrTotalVisiblity>
    <IconTotalVIsibility src="/assets/images/youtube-logo.png"/><SpanTotalVIsibility>{youtube}</SpanTotalVIsibility>
    <HrTotalVisiblity ></HrTotalVisiblity>
    <IconTotalVIsibility  src="/assets/images/linkedin.svg"/><SpanTotalVIsibility>{linkedin}</SpanTotalVIsibility>
    

  </BoxTotalVisiblity> 
  
  
  )
}

export default TotalVisibility