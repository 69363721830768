import React, { useState, useEffect, Fragment } from 'react';
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom';
import { NavigateNextTwoTone } from '@mui/icons-material';

/* ****** Elementos styled ****** */
const Div12 = styled.div`
    width: 100%;
    display: block;
`;

const Div12_Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    
`;

const Div12_Center_Tab = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background: #2D3748;
        color: #ffffff;
    }
`;

const Div1 = styled.div`
    width: 10%;
    float: left;
`;

const Div6 = styled.div`
    width: 70%;
    float: left;
`;

const Div10 = styled.div`
    width: 85%;
    float: left;
    
`;

//Estilos del menu lateral
const DIV = styled.div`
    width: 17.5%;
    height: 900px;
    margin: 0px 17px 0 0;
    padding: 52px 0 0px 0px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
    float: left;
    
    @media (max-width:500px){
        width: 50%;
        position:absolute;
        z-index: 2;
        position: fixed;
        }
   

    & > hr {
        position: absolute;
        width: 13%;
        height: 2px;
        left: 23px;
        top: 200px;
        background: #C4C4C4;
        @media (max-width:500px){
            width: 60%;
            margin: -90px 0px 0px 0px;
        }
    } 

    
        

`;  
//Estilos de los enlaces (Ayuda, Suscripcion, Invitar, Planes)
const ENLACES = styled.a`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c5282;
    cursor: pointer;
`;
//Estilos del enlace Volver
const Volver = styled.a`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 23px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2d3748;
    cursor: pointer;
`;
//Estilos de los iconos de los enlaces
const ICONS = styled.img`
    width: 13%;
    
    
`;
/* ****** END Elementos styled ****** */


const MenuLateral = ({ setPaginaActual }) => {

    const navigate = useNavigate();

  return (
    <Fragment>
        <DIV className="DIV">
            <Div12_Center className="Div12_Center">
                <Div10 className="Div10">
                    <Link to="/dashboard"><Volver
                        className="Volver"
                       
                    >
                        <ICONS className="ICONS verticalAlign mr-10" src="/assets/images/flecha.png"/>
                        <span className="verticalAlign">Volver</span>
                    </Volver></Link>
                </Div10>
            </Div12_Center>
            <hr></hr>
            {/* 
            <Div12_Center className="Div12_Center mt-50">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('administrador');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src=""/>
                            <span className="verticalAlign">Administrador</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            */}
            <Div12_Center className="Div12_Center mt-50">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('cuentas');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src=""/>
                            <span className="verticalAlign">Conectar mis RRSS</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            <Div12_Center className="Div12_Center">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('cuenta');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src="/assets/images/suscripcion.png"/>
                            <span className="verticalAlign">Cuenta y subscripción</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            <Div12_Center className="Div12_Center">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                navigate('/logout');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src="/assets/images/suscripcion.png"/>
                            <span className="verticalAlign">Logout</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>

            
        {/*
            <Div12_Center className="Div12_Center">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('facturacion');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src=""/>
                            <span className="verticalAlign">Facturación</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            <Div12_Center className="Div12_Center">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('usuarios');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src="/assets/images/invitar.png"/>
                             <span className="verticalAlign">Usuarios</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            <Div12_Center className="Div12_Center">
                <Div6 className="Div6">
                    <p>
                        <ENLACES 
                            className="ENLACES"
                            onClick={ (e) => {
                                e.preventDefault();
                                setPaginaActual('ayuda');
                            }}
                        >
                            <ICONS className="ICONS verticalAlign mr-10" src="/assets/images/Ayuda.png"/>
                             <span className="verticalAlign">Ayuda</span>
                        </ENLACES>
                    </p>
                </Div6>
            </Div12_Center>
            */}
        </DIV>
    </Fragment>
  )
}

export default MenuLateral