import React, {Fragment, useEffect, useState} from "react";
import MenuSuperior from "../componentes/menuSuperior";
import {useDispatch, useSelector} from 'react-redux';
import dateFormat, { masks } from "dateformat";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Link, useNavigate } from "react-router-dom";
import {getParameterByName} from "../lib/helpers";
import '../planes/checkbox.css';
import { style } from "@mui/system";

import PagoStripe from '../componentes/stripe/pagoStripe';

import BasicPlan from "../componentes/planes_cards/BasicPlan";
import FreePlan from "../componentes/planes_cards/FreePlan";
import PlusPlan from "../componentes/planes_cards/PlusPlan";
import AgencyPlan from "../componentes/planes_cards/AgencyPlan";
import { useParams } from 'react-router-dom';

import FreeInfluencerPlan from "../componentes/planes_cards/FreeInfluencerPlan";
import BasicInfluencerPlan from "../componentes/planes_cards/BasicInfluencerPlan";

import { updatePlanClient } from "../client/actions";

const Col_12 = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: block;
    float: left;
`;

const Col_12_Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Div4 = styled.div`
  width: 40%;
  float: left;
  padding-top: 5px;

`;

const Div8 = styled.div`
  width: 60%;
  float: left;
  padding-top: 5px;
`;

const Div9 = styled.div`
  width: 80%;
  float: left;
  padding-top: 5px;
`;

const DivPlan = styled.div`
  width: 326px;
  height: 561px;
  margin: 66px 10px 0 12px;
  padding: 45px 0 58.9px 30px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #aeaeae;
  display: inline-block;

  & :fist-child{
      margin-left:0px;
  }

  & > h4{
    margin: 0 44px 2.3px 1px;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  & > h5{
        flex-grow: 0;
        font-family: Roboto;
        font-size: 36px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
& > h5 > span{
        flex-grow: 0;
        font-family: Roboto;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & p{
        margin: 4.3px 0 0;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
    }
    & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
`;

const ButtonPlan= styled.button`
    margin: 32.6px 47px 0 17px;
    padding: 12px 50px 14.9px;
    border-radius: 24px;
    border: solid 0 #fff;
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2c5282;
`;

const MainDiv = styled.div`
    /* Ilustración Proceso de compra */
    width: 1159px;
    height: 772px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
    background-image: url("../assets/images/ilustracion_Proceso_compra.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
`;

const DivDatosTarjeta = styled.div`
    /* Rectangle 135 */

    width: 566px;
    height: 348px;
    left: 646px;
    top: 427px;

    background: #FFFFFF;

    /* Normal Shadow */

    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
`;

const DivInput = styled.div`
    /* _input */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 12px;

    position: static;
    height: 43px;

    /* Mono/White */

    background: #FFFFFF;
    /* Grey/Border */

    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    /* Inputs */

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
`;
const SpanPagar = styled.span`
    /* Aplicar */

    position: static;
    
    left: calc(50% - 185px/2);
    top: 26.32%;
    bottom: 26.32%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;
const BotonPagar = styled.button`
    /* Button / Big / Aplicar

    btn_verde_disabled
    */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;

    position: absolute;
    left: 69.21%;
    right: 15.86%;
    
    bottom: 18.46%;

    /* Green */

    background: #9AE6B4;
    border: 1px solid #9AE6B4;
    box-sizing: border-box;
    border-radius: 33px;

    cursor: pointer;
    position: inherit ;
`;
const Planes_Comprar = () => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const estado_request_pago = useSelector(state=> state.plan);
   
    const navigate = useNavigate();
    const {plan, periodicidad} = useParams();
    const showBtnPlanes = true;

    /* ****** STATES ****** */
    const [isCheckedAnual, setIsCheckedAnual] = useState(false);
    /* ****** END STATES ****** */


    /* ******* MODAL EDIT ****** */
    /* ******* END MODAL EDIT ****** */



    /* ****** USE-EFFECTS ****** */
    useEffect(() => {
      if (estado_request_pago.successfulPlan === true){
        dispatch(updatePlanClient());
      }
    }, [estado_request_pago.successfulPlan])
    
    /* ****** END USE-EFFECTS ****** */



    /* ****** FUNCTIONS ****** */
    /* ****** END FUNCTIONS ****** */



    /* ****** Elementos styled ****** */
    /* ****** END Elementos styled ****** */


    return (
        <Fragment>
            <MenuSuperior/>
            <div className="fondo-h1">
                <div className="pull-left">
                    <h1><b>Proceso de compra</b></h1>
                </div>
            </div>
            

            <div className="container">
                <Col_12_Center className="Col_12_Center">
                    <MainDiv className="MainDiv">
                        <Div4 className="Div4">
                            <Col_12 className="col_12">

                                {plan === 'free' ? 
                                    <FreePlan
                                        showBtnPlanes = {showBtnPlanes}
                                    />
                                : null}

                                {plan === 'basic' ? 
                                    <BasicPlan
                                        showBtnPlanes = {showBtnPlanes}
                                    />
                                : null}

                                {plan === 'plus' ? 
                                    <PlusPlan
                                        showBtnPlanes = {showBtnPlanes}
                                    />
                                : null}

                                {plan === 'agency' ? 
                                    <AgencyPlan
                                        showBtnPlanes = {showBtnPlanes}
                                    />
                                : null}
                                {plan === 'free_influencer' ? 
                                    <FreeInfluencerPlan
                                        showBtnPlanes = {showBtnPlanes}
                                    />
                                : null}

                                {plan === 'basic_influencer' ? 
                                    <BasicInfluencerPlan
                                        showBtnPlanes = {showBtnPlanes}
                                        showRenderCupon={true}
                                    />
                                : null}

                            </Col_12>
                        </Div4>
                        <Div8 className="Div8">
                        { estado_request_pago.successfulPlan !== true ? 
                            <Fragment>
                                    <Col_12 className="Col_12" style={{marginTop: '70px'}}>
                                        <span style={{fontSize: '32px'}}>¿Quieres unirte al <b>plan {plan}?</b></span>
                                    </Col_12>
                                    {
                                        plan === "free_influencer" ?
                                    <Col_12 className="Col_12" style={{marginTop: '20px'}}>
                                        <span style={{fontSize: '21px', fontWeight: '400'}}>¿Estás seguro de querer volver al Plan Free? Tus post se borrarán y no podrán recuperarse.</span>
                                    </Col_12>
                                        :
                                        <Col_12 className="Col_12" style={{marginTop: '20px'}}>
                                        <span style={{fontSize: '21px', fontWeight: '400'}}>Puedes cancelarlo cuando quieras</span>
                                    </Col_12>

                                    }
                                    
                            </Fragment>
                            :null }
                             {
                                        plan !== "free_influencer" ?
                             estado_request_pago.successfulPlan === true ? 
                            
                                <Col_12 className="Col_12" style={{marginTop: '70px'}}>
                                    <span style={{fontSize: '18px', fontWeight: '400'}}>Su plan se ha actualizado correctamente</span>
                                    <p><Link to="/dashboard">Volver al dashboard</Link></p>
                                </Col_12>
                            
                            :
                                <Fragment>
                                    <Col_12 className="Col_12" style={{marginTop: '70px'}}>
                                        <span style={{fontSize: '18px', fontWeight: '400'}}>Indica los campos de tu tarjeta de compra para continuar</span>
                                    </Col_12>
                                    <Col_12>
                                        <DivDatosTarjeta>
                                            {/* <Col_12_Center className="Col_12_Center" style={{paddingTop: '40px'}}>
                                                <Div9 className="Div9">
                                                    <label>
                                                        Card number
                                                    </label>
                                                </Div9>
                                            </Col_12_Center> */}
                                            <Col_12_Center className="Col_12_Center">
                                                <PagoStripe/>
                                            </Col_12_Center>
                                        </DivDatosTarjeta>
                                    </Col_12>
                                </Fragment>

                                :
                                <BotonPagar type="submit" disabled={estado_request_pago.requestingPlan === true? 'disabled' : ''}
                                onClick={
                                    (e)=>{
                                        e.preventDefault();
                                        //Hay que ver cómo procesar el cambio a FREE aquí
                                        //dispatch(procesarPago(JSON.stringify({'paymentMethodId': id, 'invoiceId':null, 'plan': plan, 'periodicidad': periodicidad}), client));
                                    }
                                }
                                
                                >
                                {estado_request_pago.requestingPlan !== true?
                              
                                <SpanPagar >Cambiar al plan FREE</SpanPagar>
                                :
                                null
                                }
                                </BotonPagar>
                            }
                        </Div8>
                    </MainDiv>
                </Col_12_Center>
                
                    
            </div>
            {/* <PagoStripe /> */}
        </Fragment>
    );
}

export default Planes_Comprar;