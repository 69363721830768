import React, { useState, Fragment } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Field } from 'react-final-form';
import styled from '@emotion/styled';

import DropForm from '../dropzone/dropzoneSupscripcionFormulario';
import PopSelectImg from '../popSelectImg';
import SelectImgForm from './selectImgForm';
import updateAccountDetailsRequest from '../../datoscuenta/actions';

const DivButton = styled.div`
    margin: 50px 0px 0px 0px;
    float: right;
`
const Columnas = styled.div`
    width: 25%;
    float: left;
    margin: 30px;
    

    @media (max-width:500px){
        width: 90%
        }  
`
const Div = styled.div`
    width: 73%;
    height: 600px;
    margin: 5px 0px 0px 12px;
    float:left;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
    

    @media (max-width:500px){
        width: 92%
        }  

`

const Button = styled.button`
    width: 105px;
    height: 38px;
    padding: 10px 10px 10px 23px;
    border-radius: 33px;
    border: solid 1px #3a6eb0;
    background-color: #fff;
    cursor: pointer;
    color: #2c5282;
    
    &:hover{
        box-shadow: 1px 1px 2px gray
    }


    & > span {
        width: 52px;
        height: 18px;
        margin: 0 0 0 1px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }

      & > img {
        width: 12px;
        height: 12px;
        
      }
`

const Buttonperfil = styled.button`
    width: 120px;
    height: 120px;
    flex-grow: 0;
    margin: 10px;
    padding: 30px 21.5px 24.2px;
    border-radius: 201px;
    background-color: #eaeaea; 
      
`

const ButtonPerfilCargado = styled.button`
    width: 120px;
    height: 120px;
    border-radius: 201px;
    flex-grow: 0;
    border: none;
    margin: 10px;
   & > img {
        width: 120px;
        flex-grow: 0;
        height: 120px;
        border-radius: 201px;
   }
`
const H2 = styled.h2`
    padding: 0px 0px 0px 40px;
`

const Label = styled.label`
    padding: 0px 0px 0px 0px;
`

const DivField = styled.div`

   @media (max-width:500px){
    margin: 0px 30px 0px 0px;
    }  
`

const DivFieldImg = styled.div`

   @media (max-width:500px){
    margin: 0px 30px 0px 0px;
    }  

    display: flex;
    justify-content: center;
`


const CuentaSubscripcionForm = ({userData}) => {

    const client = useSelector(state=> state.client);
    const dispatch = useDispatch();

    const [img, cargarImg] = useState('');

    const [mostrarCrear, setMostrarCrear] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);   

    const [imagenesPerfil, setImagenesPerfil] = useState([
        "assets/images/simpson1.png",
        "assets/images/simpson2.png",
        "assets/images/simpson3.png",
        "assets/images/simpson4.png",
        "assets/images/simpson5.png",
        "assets/images/simpson6.png",
        "assets/images/simpson7.png"
    ]);

    const [btnForm, setBtnForm] = useState('Editar');


   
    const onSubmit = (values, e) =>{
        //e.preventDefault();

        

        if (!isDisabled){
            setIsDisabled(true)
        } else {
            setIsDisabled(false);
        }

        if (btnForm === 'Guardar'){
            setBtnForm('Editar');
            const datos = {
                nombre: values.nombre,
                nif: values.nif,
                cargo: values.cargo,
                telefono: values.telefono,
                idiomas: values.idiomas,
                razon: values.razon,
                cif: values.cif,
                empleados: values.empleados,
                direccion: values.direccion,
                ciudad: values.ciudad,
                provincia: values.provincia,
                pais: values.pais,
            }
            dispatch(updateAccountDetailsRequest(datos,client));
        } else {
            setBtnForm('Guardar');
        }

    }

   
  return (
    <Div>
        { <PopSelectImg
                mostrarCrear={mostrarCrear}
                setMostrarCrear={setMostrarCrear}
                img={img}
                cargarImg={cargarImg}
                imagenesPerfil={imagenesPerfil}
                setImagenesPerfil={setImagenesPerfil}
            /> }
    <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, values }) => (
      <form onSubmit={handleSubmit}>
        
          <Columnas>
            <H2>Datos Personales</H2>
            <DivField>
                <Label>Nombre</Label>
                <Field 
                    className="input_text_1 w200"
                    name="nombre"
                    component="input"
                    type="text"
                    placeholder="Nombre y apellidos"
                    disabled={isDisabled}
                    defaultValue={userData[0].empresa}
                />
            </DivField>
            <DivField >
            <Label>NIF</Label>
            <Field 
                className="input_text_1 w100"
                name="nif"
                component="input"
                type="text"
                placeholder="Nif"
                disabled={isDisabled}
                defaultValue={userData[0].nif}
                />
            </DivField>
            <DivField>
                <Label>Cargo</Label>
                <Field 
                    className="input_text_1 w200"
                    name="cargo"
                    component="input"
                    type="text"
                    placeholder="Tu cargo en la empresa"
                    disabled={isDisabled}
                    defaultValue={userData[0].cargo}
                />
            </DivField>
            <DivField>
                <Label>Teléfono</Label>
                <Field 
                    className="input_text_1 w100"
                    name="telefono"
                    component="input"
                    type="number"
                    placeholder="Nº de teléfono"
                    disabled={isDisabled}
                    defaultValue={userData[0].telefono}
                />
            </DivField>
            <DivField >
                <Label>Idioma</Label>
                <Field name="idiomas" component="select" className="input_text_1 w100" disabled = {isDisabled}>
                <option value="español">Español</option>
                <option value="ingles">Ingles</option>
                
                </Field>
            </DivField>
            
          </Columnas>
          <Columnas>
            {client.decode.roles.includes('ROLE_USER') ?
                <Fragment>
                    <H2>Datos de la empresa</H2>
                    <DivField >
                        <Label>Razon Social</Label>
                        <Field className="input_text_1 w100"
                        name="razon"
                        component="input"
                        type="text"
                        placeholder="Nombre de la Empresa"
                        disabled={isDisabled}
                        />
                    </DivField>
                    <DivField >
                        <Label>CIF</Label>
                        <Field 
                            className="input_text_1 w100"
                            name="cif"
                            component="input"
                            type="text"
                            placeholder="X-00000000"
                            disabled={isDisabled}
                            defaultValue={userData[0].cifEmpresa}
                        />
                    </DivField>
                    <DivField >
                        <Label>Números de empleados</Label>
                        <Field name="empleados" component="select" className="input_text_1 w100" disabled = {isDisabled}>
                        <option >Elige una opción</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        
                        </Field>
                    </DivField>
                </Fragment>
            : null}
            
            <DivField >
                <DivFieldImg style={{width: '100%'}}>
                <Label>Imagen de Perfil </Label><br></br>
                </DivFieldImg>
                <DivFieldImg style={{width: '100%'}}>
                { 
                    img ?

                    <ButtonPerfilCargado
                    onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear);}}
                    ><img src={img}/>
                    </ButtonPerfilCargado>

                    

                    :

                    <Buttonperfil 
                    onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear);}}
                    >Foto sin cargar</Buttonperfil>
                    
                
                }
                </DivFieldImg>
                
                
            </DivField>
          </Columnas>
          <Columnas>
            <H2>Dirección</H2>
            <DivField>
                <Label>Dirección</Label>
                <Field
                    className="input_text_1 w100"
                    name="direccion"
                    component="input"
                    type="text"
                    placeholder="Dirección de la empresa"
                    disabled={isDisabled}
                    defaultValue={userData[0].direccion}
                />
            </DivField>
            <DivField>
                <Label>Ciudad</Label>
                <Field 
                    className="input_text_1 w100"
                    name="ciudad"
                    component="input"
                    type="text"
                    placeholder="Ciudad"
                    disabled={isDisabled}
                    defaultValue={userData[0].ciudad}
                />
            </DivField>
            <DivField>
                <Label>Provincia</Label>
                <Field 
                    className="input_text_1 w100"
                    name="provincia"
                    component="input"
                    type="text"
                    placeholder="Provincia"
                    disabled={isDisabled}
                    defaultValue={userData[0].provincia}
                />
            </DivField>
            <DivField >
                <Label>País</Label>
                <Field
                    className="input_text_1 w100"
                    name="pais"
                    component="input"
                    type="text"
                    placeholder="País"
                    disabled={isDisabled}
                    defaultValue={userData[0].pais}
                />
            </DivField>
            <DivButton>
                <Button action="submit"><img src="/assets/images/editButton.png"/><span>{btnForm}</span></Button> 
            </DivButton>
          </Columnas>
     
        </form>
     )}
    />
   </Div>
  )
}

export default CuentaSubscripcionForm