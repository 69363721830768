import {
  RECOVERY_REQUESTING,
} from './constants'

// In order to perform an action of type RECOVERY_REQUESTING
// we need an email and password
const recoveryRequest = function recoveryRequest ({ email }) {
  return {
    type: RECOVERY_REQUESTING,
    email
  }
}

// Since it's the only one here
export default recoveryRequest
