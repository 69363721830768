import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { handleApiErrors } from '../lib/api-errors'
import {
  UPDATEACCOUNTDETAILS_REQUESTING,
  UPDATEACCOUNTDETAILS_SUCCESS,
  UPDATEACCOUNTDETAILS_ERROR,
  GET_ACCOUNT_DETAILS_REQUESTING,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_ERROR,

} from './constants'

// The url derived from our .env file
const actualizarDatosCuentaURL = `${process.env.REACT_APP_API_URL}/api/users/`;
const getUserInfoURL = `${process.env.REACT_APP_API_URL}/api/users/`;


function obtenerUserInfoApi (client) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  

  return fetch(getUserInfoURL + client.decode.id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      //'Origin': 'http://localhost:3000'
    },
    //body: JSON.stringify({})
    mode: 'cors'
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}

function actualizarDatosApi (datos, client) {
  let datosCuenta={
    "nombre": datos.nombre,
    "apellidos": datos.apellidos,
    "idioma": datos.idioma,
    "empresa": datos.nombre,
    "cifEmpresa": datos.nif,
    "direccion": datos.direccion,
    "cargo": datos.cargo,
    "telefono": datos.telefono,
    "numeroEmpleados": datos.numero_empleados,
    //"sector": "string",
    "privacyPolicy": datos.acepto,
    "nif": datos.nif,
    //"razonSocial": "string",
    "ciudad": datos.ciudad,
    "provincia":datos.provincia,
    "pais": datos.pais
  }
  /*
  acepto: true
apellidos: "apellidos"
cargo: "cargo"
cif: "cif"
cuidad: "ciudad"
direccion: "direccion"
nif: "nif"
nombre: "nombre"
numero_empleados: "1"
pais: "pais"
provincia: "provincia"
razon_social: "razon social"
telefono: "tlf"
  */

  return fetch(actualizarDatosCuentaURL+client.decode.id, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(datosCuenta),
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      console.log(error);
      throw error })
}

function* obtenerUserInfoFlow (action) {
  try {
    const {client} = action;
    // console.log(client);
    // pulls "calls" to our signupApi with our email and password
    // from our dispatched signup action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield call(obtenerUserInfoApi, client)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type SIGNUP_SUCCESS with
    // the successful response.
    yield put({ type: GET_ACCOUNT_DETAILS_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: GET_ACCOUNT_DETAILS_ERROR, error })
  }
}


// This will be run when the UPDATEACCOUNTDETAILS_REQUESTING
// Action is found by the watcher
function* actualizarDatosFlow (action) {
  try {
    const { client, datos } = action
    const response = yield call(actualizarDatosApi, datos,client)
    yield put({ type: UPDATEACCOUNTDETAILS_SUCCESS, response })
    if (client.decode.is_verified===false){
      yield delay(1); // Add delay
      yield put(push('/verificacion'));
      document.location='/verificacion';
    }else{
      yield put(push('/dashboard'));
    }

  } catch (error) {
    // if the api call fails, it will "put" the UPDATEACCOUNTDETAILS_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: UPDATEACCOUNTDETAILS_ERROR, error })
  }
}




// Watches for the UPDATEACCOUNTDETAILS_REQUESTING action type
// When it gets it, it will call verificarFlow()
// WITH the action we dispatched
function* actualizarDatosCuentaWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(UPDATEACCOUNTDETAILS_REQUESTING, actualizarDatosFlow)
  yield takeLatest(GET_ACCOUNT_DETAILS_REQUESTING, obtenerUserInfoFlow)
 
}

export default actualizarDatosCuentaWatcher
