import { SportsTennis } from '@mui/icons-material';
import {
  SINGLE_CAMPAIGN_INFLUENCER_REQUESTING,
  SINGLE_CAMPAIGN_INFLUENCER_SUCCESS,
  SINGLE_CAMPAIGN_INFLUENCER_ERROR,
  DELETE_MENTIONED_ERROR,
  SINGLE_CAMPAIGN_INFLUENCER_STORIES_SUCCESS,
  SINGLE_CAMPAIGN_INFLUENCER_STORIES_ERROR,
  DATOS_GRAFICA_SPLINE_INFLUENCER_SUCCESS,
  DATOS_GRAFICA_SPLINE_INFLUENCER_ERROR,
  FILTROS_APPLY_RESPONSE,
  DATOS_GRAFICA_DONUT_INFLUENCER_SUCCESS,
  DATOS_GRAFICA_DONUT_INFLUENCER_ERROR,
  FILTROS_RESULT_UPDATE_VALUE,
  SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_ERROR,
  SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER,
  SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_END,
  SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST,
  SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST_STATE,
  SINGLE_CAMPAIGN_INFLUENCER_SOLICITAR_MENCIONES_USER_SUCCESS,
  DELETE_POST_INFLUENCER_SUCCESS,
  INFLUENCER_DATOS_SUCCESS,
  INFLUENCER_INFORME_REQUESTING,
  INFLUENCER_INFORME_SUCCESS,
  INFLUENCER_INFORME_ERROR
} from './constants'

const initialState = {
  datos: [],
  post_influencer: [],
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  stories: [],
  dataGraficaSpline: [],
  filtro: {},
  dataGraficaDonut: [],
  addInfluencer:{
    requesting: false,
    error: false
  },
  addHashtagsState:{
    requesting: false,
    error: false,
    ended: false,
    
  },
  mencionesDelUsername:{
      update: 0,
      data: {}
  },
  refresh: Math.random(),
  informe:{
    requesting: false,
    error: false
  },
}

const reducer = function campaingReducer (state = initialState, action) {
  switch (action.type) {

    case INFLUENCER_INFORME_REQUESTING:
      return{
        ...state,
        informe:{
          requesting: true,
          error: false
        }
      }

      case INFLUENCER_INFORME_SUCCESS:
        return{
          ...state,
          informe:{
            requesting: false,
            error: false
          }
        }
  
        case INFLUENCER_INFORME_ERROR:
          return{
            ...state,
            informe:{
              requesting: false,
              error: true
            }
          }
    
  
      case DELETE_POST_INFLUENCER_SUCCESS:
        let found = state.post_influencer['hydra:member'].filter(function(item) { return item.id !== action.payload.idPost; });
        let auxMentioned = state.post_influencer;
        auxMentioned['hydra:member'] = found;
        return {
          ...state,
          post_influencer: auxMentioned,
          refresh: Math.random()
        }

    case INFLUENCER_DATOS_SUCCESS:
      return {
        ...state,
        datos: action.response
      }
    case SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST_STATE:
      return {
        ... state,
        addHashtagsState: action.payload
      }
    case SINGLE_CAMPAIGN_INFLUENCER_UPDATE_HASHTAG_LIST:
      let auxC=state.post_influencer;
      auxC.hashtagsCampaign.push(action.respuestasHashtag);
    return {
      ...state,
      post_influencer: auxC
    }
    case SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER:
      let aux=state.addInfluencer;
      aux['requesting']=true;
      return {
          ...state,
          aux
      }
      case SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_END:
      return {
          ...state,
          addInfluencer:{
            requesting: false,
            error: false
          }
      }
      case SINGLE_CAMPAIGN_INFLUENCER_ADDINFLUENCER_ERROR:
        return {
            ...state,
            addInfluencer:{
              requesting: false,
              error: true
            }
        }
      case FILTROS_RESULT_UPDATE_VALUE:
      let campaignAux=state.post_influencer;
      campaignAux[action.payload.campo]=action.payload.valor;
      return {
        ...state,
        post_influencer: campaignAux
      }
    case FILTROS_APPLY_RESPONSE:
      return {
        ...state,
        filtro: action.filtro
      }
    case SINGLE_CAMPAIGN_INFLUENCER_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
      }
    // Successful?  Reset the login state.
    case SINGLE_CAMPAIGN_INFLUENCER_SUCCESS:
      return {
        ...state,
        post_influencer: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
      }



    // Append the error returned from our api
    // set the success and requesting flags to false
    case SINGLE_CAMPAIGN_INFLUENCER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        mentionedMedia:[]
      }

    case SINGLE_CAMPAIGN_INFLUENCER_STORIES_SUCCESS:
      return {
        ...state,
        stories: action.responseStories
      }

    case DATOS_GRAFICA_SPLINE_INFLUENCER_SUCCESS:
      return {
        ...state,
        dataGraficaSpline: action.responseDataGraficaSpline
      }

    case DATOS_GRAFICA_DONUT_INFLUENCER_SUCCESS:
      return {
        ...state,
        dataGraficaDonut: action.responseDataGraficaDonut
      }
    
    default:
      return state
  }
}

export default reducer
