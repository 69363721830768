import {
  SINGLE_CAMPAIGN_REQUESTING,
  SINGLE_CAMPAIGN_SUCCESS,
  SINGLE_CAMPAIGN_ERROR,
  SINGLE_CAMPAIGN_MENTIONED_SUCCESS,
  SINGLE_CAMPAIGN_MENTIONED_ERROR,
  SINGLE_CAMPAIGN_USERNAMES_SUCCESS,
  SINGLE_CAMPAIGN_USERNAMES_ERROR,
  DELETE_MENTIONED_SUCCESS,
  DELETE_MENTIONED_ERROR,
  SINGLE_CAMPAIGN_STORIES_SUCCESS,
  SINGLE_CAMPAIGN_STORIES_ERROR,
  DATOS_GRAFICA_SPLINE_SUCCESS,
  DATOS_GRAFICA_SPLINE_ERROR,
  FILTROS_APPLY_RESPONSE,
  DATOS_GRAFICA_DONUT_SUCCESS,
  DATOS_GRAFICA_DONUT_ERROR,
  FILTROS_RESULT_UPDATE_VALUE,
  SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR,
  SINGLE_CAMPAIGN_ADDINFLUENCER,
  SINGLE_CAMPAIGN_ADDINFLUENCER_END,
  SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST,
  SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE,
  SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS,
  CAMPAING_DATOS_SUCCESS,
  CAMPAING_ADD_CUENTA_SUCCESS,
  CAMPAING_INFORME_REQUESTING,
  CAMPAING_INFORME_ERROR,
  CAMPAING_INFORME_SUCCESS
} from './constants'

const initialState = {
  campaign: [],
  camps: [],
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  mentionedMedia: [],
  usernames: [],
  stories: [],
  dataGraficaSpline: [],
  filtro: {},
  datos: [],
  dataGraficaDonut: [],
  addInfluencer:{
    requesting: false,
    error: false
  },
  addHashtagsState:{
    requesting: false,
    error: false,
    ended: false,
    
  },
  mencionesDelUsername:{
      update: 0,
      data: {}
  },
  refreshCuentas: 0,
  informe:{
    requesting: false,
    error: false,
    tipo: ''
  }
}

const reducer = function campaingReducer (state = initialState, action) {
  switch (action.type) {
    case CAMPAING_INFORME_REQUESTING:
      return{
        ...state,
        informe:{
          requesting: true,
          error: false,
          tipo: action.payload
        }
      }

      case CAMPAING_INFORME_SUCCESS:
        return{
          ...state,
          informe:{
            requesting: false,
            error: false,
            tipo: ''
          }
        }
  
        case CAMPAING_INFORME_ERROR:
          return{
            ...state,
            informe:{
              requesting: false,
              error: true,
              tipo: ''
            }
          }
    
    case CAMPAING_ADD_CUENTA_SUCCESS:
        if (action.response.hasOwnProperty("success") && action.response.success===true){
          let campaignAux=state.campaign;
           campaignAux.cuentas=action.response.resultCuentas;
           return {...state,
            campaign:campaignAux,
            refreshCuentas: Math.random()
          }
          
        }
        return {...state}

    case CAMPAING_DATOS_SUCCESS:
      return {
        ...state,
        datos: action.response
      }
    case SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS:
      let auxMenUser= state.mencionesDelUsername;
      auxMenUser.data[action.payload.username] ={
          requesting: false,
          error: false,
          result: action.payload.respuesta
      }
      auxMenUser.update=Math.random();
      return{
        ...state,
        mencionesDelUsername: auxMenUser
      }
    case SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE:
      return {
        ... state,
        addHashtagsState: action.payload
      }
    case SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST:
      let auxC=state.campaign;
      auxC.hashtagsCampaign.push(action.respuestasHashtag);
    return {
      ...state,
      campaign: auxC
    }
    case SINGLE_CAMPAIGN_ADDINFLUENCER:
      let aux=state.addInfluencer;
      aux['requesting']=true;
      return {
          ...state,
          aux
      }
      case SINGLE_CAMPAIGN_ADDINFLUENCER_END:
      return {
          ...state,
          addInfluencer:{
            requesting: false,
            error: false
          }
      }
      case SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR:
        return {
            ...state,
            addInfluencer:{
              requesting: false,
              error: true
            }
        }
      case FILTROS_RESULT_UPDATE_VALUE:
      let campaignAux=state.campaign;
      campaignAux[action.payload.campo]=action.payload.valor;
      return {
        ...state,
        campaign: campaignAux
      }
    case FILTROS_APPLY_RESPONSE:
      return {
        ...state,
        filtro: action.filtro
      }
    case SINGLE_CAMPAIGN_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
      }
    // Successful?  Reset the login state.
    case SINGLE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        mentionedMedia:[]
      }

    case SINGLE_CAMPAIGN_MENTIONED_SUCCESS:
      return {
        ...state,
        mentionedMedia: action.responseMentioned
      }

    case SINGLE_CAMPAIGN_USERNAMES_SUCCESS:
      return {
          ...state,
          usernames: action.responseUsernames
      }

    // Append the error returned from our api
    // set the success and requesting flags to false
    case SINGLE_CAMPAIGN_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        mentionedMedia:[]
      }

    case DELETE_MENTIONED_SUCCESS:
      let found = state.mentionedMedia['hydra:member'].filter(function(item) { return item.id !== action.payload.idPost; });
      let auxMentioned = state.mentionedMedia;
      auxMentioned['hydra:member'] = found;
      return {
        ...state,
        mentionedMedia: auxMentioned
      }
    
    case SINGLE_CAMPAIGN_STORIES_SUCCESS:
      return {
        ...state,
        stories: action.responseStories
      }

    case DATOS_GRAFICA_SPLINE_SUCCESS:
      return {
        ...state,
        dataGraficaSpline: action.responseDataGraficaSpline
      }

    case DATOS_GRAFICA_DONUT_SUCCESS:
      return {
        ...state,
        dataGraficaDonut: action.responseDataGraficaDonut
      }
    
    default:
      return state
  }
}

export default reducer
