import React, { Fragment,useState, useEffect } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {useDispatch, useSelector} from 'react-redux';
import Messages from '../notifications/Messages'
import Errors from '../notifications/Errors'
import RegistroForm from '../componentes/formularios/registroForm';
import MenuSuperior from "../componentes/menuSuperior";
import signupRequest from './actions';
import styled from '@emotion/styled';

const LoginRight=styled.div`
  background: #f5f5f5 url('/assets/images/welcome.png') center center no-repeat;
  vertical-align: top;
  display: table-cell;
      @media (max-width:500px) {
        display: none;
      }
  `

const DivResponsive = styled.div`
    @media (max-width:500px) {
      margin: 0px 0px 0px -20px;
    }
     
`

const NoTengoCuenta=styled.p`
  margin: 88px 2px 20px 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #224957;
  & .text-style-2 {
    font-weight: bold;
    color: #2d3748;
  }
`
const ContinuarLoginSocial=styled.div`
  position: relative;
  margin-top:40px;
    & .linea{
    width: 100%;
      height: 1px;
      flex-grow: 0;
      margin: 32.5px 0 31.5px 5px;
      padding: 0.5px 0;
      transform: rotate(-360deg);
      background-color: #2c5282;
      text-align:center;  
      display: block;
      position: absolute;
      top: 0px;
      margin: 10px;
      z-index: 1;
    }
    & .texto-continuar{
      align-self: stretch;
      flex-grow: 1;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #444b59;
      position: relative;
      z-index:2;
      background: #fff;
      margin: 0px auto;
      display: block;
      width: 150px;
    }
    padding-bottom: 50px;
    clear:both;
  `
  const LoginSocialDiv=styled.div`
    margin: 0px auto;
    margin-bottom:20px;
    text-align:center;
  `

const Registro = () => {
  

  const signup = useSelector(state => state.signup);
  //const [tipo, setTipo] = useState('empresa');
  const [tipo, setTipo] = useState('influencer');
  const dispatch = useDispatch();
  let {requesting,
    successful,
    messages,
    errors} = signup;
    

    return (
      <Fragment>
        {
                successful ? (<Navigate to={{ pathname: '/account-details', state: ''}} />) : null
        }
        <MenuSuperior
          showDerecha= "false"
          marginBottom="No"
          />

 <DivResponsive className="col-md-12 div-table">
        <DivResponsive className="col-md-6 inline-block col-table">
               <p className="welcome">Welcome!</p>
          <ul className="select_opciones_login">
            <li style={process.env.REACT_APP_MOSTRAR_REGISTRO_EMPRESA === 'false' ? {display:'none'} : null} className={tipo === 'empresa' ? 'active' : null} onClick={(e)=>{setTipo("empresa");}}><span>Empresa</span></li>
            <li className={tipo === 'influencer' ? 'active' : null}  onClick={(e)=>{setTipo("influencer");}}><span>Influencer</span></li>
          </ul>
          
      <div className="signup">
        <RegistroForm 
        tipo={tipo}
        />

        <div className="col-md-6 mauto" style={{display:'none'}}>
            <ContinuarLoginSocial>
              <span className="linea"></span><span className="texto-continuar">o continuar con</span>
            </ContinuarLoginSocial>

            <LoginSocialDiv><img src="/assets/images/SignGoogle.png"/></LoginSocialDiv>
            <LoginSocialDiv><img src="/assets/images/SignFacebook.png"/></LoginSocialDiv>
        </div>

        <div className="auth-messages">
          {
            /*
            These are all nothing more than helpers that will show up
            based on the UI states, not worth covering in depth.  Simply put
            if there are messages or errors, we show them
            */
          }
          {!requesting && !!errors.length && (
            <Errors message="Failure to signup due to:" errors={errors} />
          )}
          {!requesting && !!messages.length && (
            <Messages messages={messages} />
          )}
         
         
        </div>
      </div>
      </DivResponsive>

      <LoginRight className="col-md-6 hideMovil">

        </LoginRight>
      </DivResponsive>
      </Fragment>
    )
  }

export default Registro
