import styled from '@emotion/styled'
import { useState,useEffect } from 'react'
import {updateCuentasClient} from '../../client/actions'
import {useDispatch, useSelector} from 'react-redux';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';

//Estilos del contenedor 
const DIV = styled.div`
        width: 27%;
        height: 320px;
        margin: 9px 10px 9px 15px;
        padding: 39px 57px 55px 76px;
        border-radius: 10px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        float: left;

        @media (max-width:500px){
          width: 67%
        }
    `
//Estilos del boton de Linkedin
const CONT = styled.div`
        width: 345px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin: 0 47px 44px 0;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
        background-color: #fff;

        @media (max-width:500px){
          margin: 0px 0px 0px -40px;
          } 
        
        &:hover {
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 4px 4px 8px rgba(0, 0, 0, 0.168);
          cursor: pointer;
        }

        & > span {
         flex-grow: 0;
         font-family: Helvetica;
         font-size: 20px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         text-align: left;
         color: rgba(0, 0, 0, 0.54);

         & > img {
          width: 30px;
          height: 30px;
          margin: -5px 15px -7px 0px;     
                }
        & > button{
          box-shadow:none;
          font-size:20px;
        }

        @media (max-width:500px){
          margin: 0px 0px 0px 30px;
          }

        }

  `

  const TEXTDIV = styled.p`
flex-grow: 0;

font-family: Roboto;
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
margin-left: 10px;

& > span {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba
}

& > img {
  width: 15px;
  height: 15px;

}

`

const ContenedorLinkedin = ({cuentas}) => {

  const dispatch = useDispatch();
  const client = useSelector(state=> state.client);
  const [urlLoginLinkedin, setUrlLoginLinkedin] = useState('');
  /*useEffect(()=>{
      //obtener la url de linkedin para guardar el state y demás
      fetch(`${process.env.REACT_APP_API_URL}/api/linkedin-login-url/` , {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
        }
      })
      .then(response => response.json())
      .then(respuesta =>{
        setUrlLoginLinkedin(respuesta.url);
        //linkedin devuelve state y code
      })
      .catch((error) => { 
        console.log(error);
        throw error });

  },[]);*/

  const { linkedInLogin } = useLinkedIn({
      clientId: `${process.env.REACT_APP_LINKEDIN_CLIENT}`,
      redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
      scope: 'r_liteprofile,r_emailaddress,w_member_social,w_organization_social,r_organization_social,rw_ads,r_ads,r_organization_admin,r_1st_connections_size',
      onSuccess: (code) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/updateTokenLinkedin` , {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
          },
          body: JSON.stringify({
            'token':  code
          })
        })
        .then(response => response.json())
        .then(respuesta =>{
          if (respuesta.hasOwnProperty("cuentas")){
            dispatch(updateCuentasClient(respuesta.cuentas));
          }
        })
        .catch((error) => { 
          console.log(error);
          throw error })
      },
      onError: (error) => {
        console.log(error);
      },
    });


     
        
  return (
        <DIV>
        <CONT>
        <img
      onClick={linkedInLogin}
      src={linkedin}
      alt="Sign in with Linked In"
      style={{ maxWidth: '180px', cursor: 'pointer' }}
    />
        </CONT>
        {cuentas && cuentas.hasOwnProperty('linkedin') && cuentas.linkedin?.map ((cuenta) => cuenta.activa ?
            <TEXTDIV key="cuenta.id">
              <img className="verticalAlign mr-10" src="/assets/images/checkGreen.png" />
              <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
            </TEXTDIV>
          : 
            <TEXTDIV  key="cuenta.id">
              <img className="verticalAlign mr-10" src="/assets/images/checkRed.png" />
              <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
            </TEXTDIV>
          )}

    </DIV>
  )
}

export default ContenedorLinkedin