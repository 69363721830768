/* *************** CAMPAIGN *************** */
export const SINGLE_CAMPAIGN_REQUESTING = 'SINGLE_CAMPAIGN_REQUESTING';
export const SINGLE_CAMPAIGN_SUCCESS = 'SINGLE_CAMPAIGN_SUCCESS';
export const SINGLE_CAMPAIGN_ERROR = 'SINGLE_CAMPAIGN_ERROR';
/* *************** END CAMPAIGN *************** */


/* *************** MENTIONED MEDIA *************** */
export const SINGLE_CAMPAIGN_MENTIONED_SUCCESS='SINGLE_CAMPAIGN_MENTIONED_SUCCESS';
export const SINGLE_CAMPAIGN_MENTIONED_ERROR='SINGLE_CAMPAIGN_MENTIONED_ERROR';
export const SINGLE_CAMPAIGN_MENTIONED_REQUESTING='SINGLE_CAMPAIGN_MENTIONED_REQUESTING';
export const DELETE_MENTIONED_REQUESTING='DELETE_MENTIONED_REQUESTING';
export const DELETE_MENTIONED_SUCCESS='DELETE_MENTIONED_SUCCESS';
export const DELETE_MENTIONED_ERROR = 'DELETE_MENTIONED_ERROR';
/* *************** END MENTIONED MEDIA *************** */


/* *************** USERNAMES *************** */
export const SINGLE_CAMPAIGN_USERNAMES_REQUESTING='SINGLE_CAMPAIGN_USERNAMES_REQUESTING';
export const SINGLE_CAMPAIGN_USERNAMES_SUCCESS='SINGLE_CAMPAIGN_USERNAMES_SUCCESS';
export const SINGLE_CAMPAIGN_USERNAMES_ERROR='SINGLE_CAMPAIGN_USERNAMES_ERROR';
/* *************** END USERNAMES *************** */


/* *************** STORIES *************** */
export const SINGLE_CAMPAIGN_STORIES_REQUESTING = 'SINGLE_CAMPAIGN_STORIES_REQUESTING';
export const SINGLE_CAMPAIGN_STORIES_SUCCESS = 'SINGLE_CAMPAIGN_STORIES_SUCCESS';
export const SINGLE_CAMPAIGN_STORIES_ERROR = 'SINGLE_CAMPAIGN_STORIES_ERROR';
/* *************** END STORIES *************** */


/* *************** FILTROS *************** */
export const FILTROS_APPLY = 'FILTROS_APPLY';
export const FILTROS_APPLY_RESPONSE = 'FILTROS_APPLY_RESPONSE';
export const FILTROS_RESULT_UPDATE_VALUE = 'FILTROS_RESULT_UPDATE_VALUE';

/* *************** FILTROS *************** */
/* *************** GRÁFICAS *************** */
export const DATOS_GRAFICA_SPLINE_REQUESTING = 'DATOS_GRAFICA_SPLINE_REQUESTING';
export const DATOS_GRAFICA_SPLINE_SUCCESS = 'DATOS_GRAFICA_SPLINE_SUCCESS';
export const DATOS_GRAFICA_SPLINE_ERROR = 'DATOS_GRAFICA_SPLINE_ERROR';
export const DATOS_GRAFICA_DONUT_REQUESTING = 'DATOS_GRAFICA_DONUT_REQUESTING';
export const DATOS_GRAFICA_DONUT_SUCCESS = 'DATOS_GRAFICA_DONUT_SUCCESS';
export const DATOS_GRAFICA_DONUT_ERROR = 'DATOS_GRAFICA_DONUT_ERROR';
/* *************** END GRÁFICAS *************** */

export const SINGLE_CAMPAIGN_ADDINFLUENCER = 'SINGLE_CAMPAIGN_ADDINFLUENCER';
export const SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR = 'SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR';
export const SINGLE_CAMPAIGN_ADDINFLUENCER_END = 'SINGLE_CAMPAIGN_ADDINFLUENCER_END';
export const SINGLE_CAMPAIGN_DELETE_INFLUENCER = 'SINGLE_CAMPAIGN_DELETE_INFLUENCER';
export const SINGLE_CAMPAIGN_DELETE_INFLUENCER_END = 'SINGLE_CAMPAIGN_DELETE_INFLUENCER_END';
export const SINGLE_CAMPAIGN_ADD_HASHTAG = 'SINGLE_CAMPAIGN_ADD_HASHTAG';
export const SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST = 'SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST';
export const SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE = 'SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE';


export const SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST = 'SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST';
export const SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS = 'SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS';

export const CAMPAING_DATOS_SUCCESS='CAMPAING_DATOS_SUCCESS';
export const CAMPAING_DATOS_ERROR='CAMPAING_DATOS_ERROR';
export const CAMPAING_DATOS_REQUESTING='CAMPAING_DATOS_REQUESTING';

export const CAMPAING_INFORME_REQUESTING='CAMPAING_INFORME_REQUESTING';
export const CAMPAING_INFORME_ERROR='CAMPAING_INFORME_ERROR';
export const CAMPAING_INFORME_SUCCESS='CAMPAING_INFORME_SUCCESS';

/* Cuentas */
export const CAMPAING_ADD_CUENTA='CAMPAING_ADD_CUENTA';
export const CAMPAING_ADD_CUENTA_SUCCESS='CAMPAING_ADD_CUENTA_SUCCESS';
