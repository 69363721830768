import styled from '@emotion/styled'
import React, { Component, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';

import {updateCuentasClient} from '../../client/actions'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//Estilos del contenedor 
const DIV = styled.div`
        width: 27%;
        height: 320px;
        margin: 9px 10px 9px 15px;
        padding: 39px 57px 55px 76px;
        border-radius: 10px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        float: left;

        @media (max-width:500px){
          width: 67%
        }
    `

    
//Estilos del boton de Facebook
const CONT = styled.div`
        width: 345px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px;
        margin-bottom: 40px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
        &:hover {
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 4px 4px 8px rgba(0, 0, 0, 0.168);
          cursor: pointer;
        }

        @media (max-width:500px){
          margin: 0px 0px 0px -40px;
          }

        & > span {
            flex-grow: 0;
            font-family: Helvetica;
            font-size: 20px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(0, 0, 0, 0.54);

            & > img {
              width: 30px;
              height: 30px;
              margin: -5px 8px -7px 0px;
            }

            @media (max-width:500px){
              margin: 0px 0px 0px 30px;
              }
        }
  `      
    //Estilos de texto del contenedor 
    const TEXTDIV = styled.p`
            flex-grow: 0;

            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #2d3748;
            margin-left: 10px;

            & > span {
              flex-grow: 0;
              font-family: Roboto;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: rgba
            }

            & > img {
              width: 15px;
              height: 15px;

            }

`    
const ContenedorFacebook = ({cuentas}) => {

  const dispatch = useDispatch();
  const client = useSelector(state=> state.client);

/* ************ CUSTOM FUNCTIONS *********** */
const responseFacebook = (response) => {
  //Enviar al servidor
  fetch(`${process.env.REACT_APP_API_URL}/api/updateTokenFb` , {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify({
      'idFB': response.id,
      'token': response.accessToken
    })
  })
  .then(response => response.json())
  .then(respuesta =>{
    if (respuesta.hasOwnProperty("cuentas")){
      dispatch(updateCuentasClient(respuesta.cuentas));
    }
  })
  .catch((error) => { 
    console.log(error);
    throw error })
    
}


/* ************ END CUSTOM FUNCTIONS *********** */



  return (
  <DIV>
    <CONT>
    <FacebookLogin
          appId="370019901084564"
          autoLoad={false}
          fields="name,email"
          scope="public_profile,instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_comments,instagram_manage_insights,pages_manage_metadata"
          callback={responseFacebook}
          render={renderProps => (
            <span onClick={renderProps.onClick}><img src="/assets/images/facebook.png" />Iniciar sesion con Facebook</span>
            
          )}
        />
    
    </CONT>
    
    {cuentas && cuentas.hasOwnProperty('fb') && cuentas.fb?.map ((cuenta) => cuenta.activa ?
      <TEXTDIV>
        <img className="verticalAlign mr-10" src="/assets/images/checkGreen.png" />
        <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
      </TEXTDIV>
     : 
      <TEXTDIV>
        <img className="verticalAlign mr-10" src="/assets/images/checkRed.png" />
        <span className="verticalAlign"><b>Completed for account:</b> {cuenta.nombre}</span>
      </TEXTDIV>
    )}

  </DIV>
  )
}

export default ContenedorFacebook;