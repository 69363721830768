import React, {Fragment, useEffect, useState} from "react";
import MenuSuperior from "../componentes/menuSuperior";
import {useDispatch, useSelector} from 'react-redux';
import listadoCamps  from './actions';
import {singleCampaign} from '../campaign/actions';
import dateFormat, { masks } from "dateformat";
import styled from 'styled-components';
import NuevaCampPopup from "../componentes/nuevaCamp";
import imageTestCamp from '../test/images/testCampImg.jpeg';
import iconTrash from '../test/icons/trash-2.png';
import iconEdit from '../test/icons/Group 158.png';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useNavigate, Navigate } from "react-router-dom";
import {getParameterByName} from "../lib/helpers";
import PaginadorRow from '../componentes/PaginadorRow';

// Translations
import {useTranslation} from "react-i18next";
import ListCard from "../componentes/cards/ListCard";
import GridCard from "../componentes/cards/GridCard";

/* ****** ICONS ****** */
// import { AiFillLike } from "react-icons/ai";
/* ****** END ICONS ****** */

/* ****** COMPONENTS ****** */
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';

// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

// import { Container, Row, Col } from 'react-bootstrap';
/* ****** END COMPONENTS ****** */


    /* ****** Elementos styled ****** */
    const RowCamp = styled.div`
        margin-bottom: 10px;
        display: flex;
    `;

    const DivFondo = styled.div`
        background-color: #f5f5f5;
    `

    const Col_12 = styled.div`
        width: 100%;
        padding-left: 40px;
    `;

        const Col_12_Content = styled.div`
        width: calc(100% - 50px);
        padding-left: 40px;
        `;
    const CardImg = styled.img`
        width: 130px;
        height: 100px;
        padding: 5px 5px 5px 5px;
        float: left;
        border-radius: 10px;
    `
    
    const Div12 = styled.div`
        width: 100%;
        float: left;
    `;

    const Div12_Center = styled.div` 
        float: left;
        margin: -100px 0px 0px 400px ;
        
    `;

    const Div13 = styled.div`
        width: 100%;
        float: left;
        margin: 0px px 0px 0px ;

    `;

 
    const Div6 = styled.div`
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        margin: 15px 17.1px 14px 27px;
        padding: 0;
    `;

    const Div6_Center = styled.div`
        width: 49%;
        float: left;
        display: flex;
        justify-content: center;
    `;

    const NombreItem = styled.b`
        width: 90px;
        height: 10px;
        flex-grow: 0;
        
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
    `

    const HorizontalLine = styled.div`
        width: 1px;
        height: 50px;
        flex-grow: 0;
        margin: 19px 27px 10px 0;  
        background-color: #d4d4d4;
    `;

    const ModalEdit = styled.div`
        width: 615px;
        height: 962px;
        padding: 35px 0 150px 39px;
        border-radius: 11px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #fff;
    `;

    const Span_Nombre = styled.span`
        width: 200.9px;
        height: 19px;
        flex-grow: 0;
        margin: 0 369.1px 3px 4px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
    `
    const SinCamp=styled.p`
    font-family: Roboto;
    font-size: 23px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3748;`
    /* ****** END Elementos styled ****** */

const ListadoCampañas = () => {

    /* ******************* TRANSALATIONS ******************* */
    const [t, i18n] = useTranslation("global");
    /* ******************* END TRANSALATIONS ******************* */

    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    //Obtener el listado de las campañas
    //Cambio el redux dashboard->requesting a true para que saga lo escuche

    const campaigns = useSelector((state) => state.dashboard.camps);
    const paginacion = useSelector((state) => state.dashboard.pagination);
    const requesting = useSelector((state) => state.dashboard.requesting);
    
    const navigate = useNavigate();

    /* ****** STATES ****** */
    const [currentCamp, setCurrentCamp] = useState({
        id: '',
        nombre: '',
        fecha: '',
        likes: '',
        imagen: '',
        posts: '',
        comments: ''
    });
    const [showEditModal, setShowEditModal] = useState(false);
    const [mostrarCrear, setMostrarCrear] = useState(false);

    const [modoDisplay, setModoDisplay] = useState('grid');
    const [renderKey, setRenderKey] = useState(Math.random);

  
    /* ****** END STATES ****** */


    /* ******* MODAL EDIT ****** */
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        // bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
      };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    /* ******* END MODAL EDIT ****** */



    /* ****** USE-EFFECTS ****** */
    //Cargamos las campañas del usuario logueado
    useEffect(() => {
        dispatch(listadoCamps(client));
    }, [])
    /* ****** END USE-EFFECTS ****** */



    /* ****** FUNCTIONS ****** */
    const getCurrentCamp = (item) => {
        setCurrentCamp(item);
        // setShowEditModal(true);
    }

    const getSingleCamp = (idCamp) => {
        //dispatch(singleCamp(client, idCamp));
        //dispatch(singleCampaign(client, idCamp));
    }

    // Ir a la Single-Campaign
    const changeRoute = (idCamp) => {
        navigate("/campaign/"+idCamp);
    }

    const clickPaginacion = (paginacion) =>{
        dispatch(listadoCamps(client,paginacion));

    }
    /* ****** END FUNCTIONS ****** */

  
    return (
        <Fragment>
            {
            client.decode.roles.includes('ROLE_INFLUENCER') ? 
                  <Navigate to={{ pathname: '/dashboard_influencer', state: ''}} />
                  : null }
            <DivFondo>
            <MenuSuperior/>
            { <NuevaCampPopup 
                mostrarCrear={mostrarCrear}
                setMostrarCrear={setMostrarCrear}
            /> }
            
            <div className="fondo-h1">
                {/* ********* Botones para elegir idioma ********* */}
                { `${process.env.REACT_APP_ALLOW_MULTI_LANGUAGE}` === 'true' ? 
                    <div id="btnTranslation">
                        <button  onClick={() => i18n.changeLanguage("es")}>ES</button>
                        <button  onClick={() => i18n.changeLanguage("en")}>EN</button>
                    </div> 
                : null }
                {/* ********* END Botones para elegir idioma ********* */}
                
                <div className="pull-left">
                    <h1>{t("dashboard.title")}</h1>
                </div>
                <div className="pull-right">
                    <button className="btn_oscuro mt-10"
                    onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear);}}
                    >Crear nueva campaña</button>
                </div>
            </div>
            
            <div className="row">
                <div className="pull-right">
                    <ul className="lista_opciones">
                    <li >
                        <a href="#" onClick={(e)=>{e.preventDefault();setModoDisplay("grid");setRenderKey(Math.random);}} ><img src="/assets/images/grid.png"
                         /></a>
                        </li>
                        <li
                        >
                        <a onClick={(e)=>{e.preventDefault();setModoDisplay("list");setRenderKey(Math.random);}}>
                        <img src="/assets/images/list.png" /></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container">
            {(campaigns===undefined || campaigns.length===0  || campaigns['hydra:member'].length ===0) && requesting === false  ? 
                <Col_12 className="col_12" style={{paddingLeft: '0px'}}>
                        <img src="/assets/images/vacia.png" className="SinCampImg"/>
                        <SinCamp>Todavía no tienes ninguna campaña activa,
                                crea tu primera campaña ahora</SinCamp>
                                <div className="">
                    <button className="btn_oscuro  mauto d-block mt-10"
                    onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear);}}
                    >Crear nueva campaña</button>
                </div>

                </Col_12>
                : null}
                 { requesting === true  ? 
                <Col_12 className="col_12" style={{paddingLeft: '0px'}}>
                        <SinCamp>Obteniendo tus campañas ....</SinCamp>
                </Col_12>
                : null}
                <RowCamp>

                    <Col_12_Content className="col_12" key={`render_dashboad_${renderKey}`}>
                        {
                        
                        campaigns['hydra:member']?.map((item, i) => (
                            modoDisplay ==="list" ?
                           <ListCard 
                           index={i}
                           item={item} 
                           getCurrentCamp={getCurrentCamp}
                           handleOpen={handleOpen}
                           changeRoute={changeRoute}

                           />
                           : 
                           <GridCard
                           index={i}
                           item={item} 
                           getCurrentCamp={getCurrentCamp}
                           handleOpen={handleOpen}
                           changeRoute={changeRoute}
                           />
                         ))}
                    </Col_12_Content>
                </RowCamp> 
                
                
                {paginacion.hasOwnProperty('@id') && paginacion.hasOwnProperty('hydra:first') ? 
                
                        <PaginadorRow 
                        paginacion={paginacion}
                        callback_click={clickPaginacion}
                        />
                : null}

                <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        ariaHideApp={false}
                                    >
                                        <Box sx={style}>
                                        {/* Aquí hay que poner un formulario para editar la campaña */}

                                        <ModalEdit>
                                            <Div12>
                                                <label>Nombre: </label>
                                                <Span_Nombre>{currentCamp.nombre}</Span_Nombre>
                                            </Div12>
                                            <Div12>
                                                <label>Likes: </label>
                                                <Span_Nombre>{currentCamp.likes}</Span_Nombre>
                                            </Div12>
                                            <Div12>
                                                <label>Posts: </label>
                                                <Span_Nombre>{currentCamp.posts}</Span_Nombre>
                                            </Div12>
                                        </ModalEdit>
                                        </Box>
                                    </Modal>
            
            
            
            </div>
            
            
            </DivFondo>
            
        </Fragment>
    );
}

export default ListadoCampañas;