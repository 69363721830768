import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { handleApiErrors } from '../lib/api-errors'
import {setClient} from '../client/actions';
import {
  SIGNUP_REQUESTING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
} from './constants'

// The url derived from our .env file
const signupUrlEmpresa = `${process.env.REACT_APP_API_URL}/api/register-user`
const signupUrlInfluencer = `${process.env.REACT_APP_API_URL}/api/register-influencer`

function signupApi (email, password, tipo) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  let url = (tipo === 'empresa'? signupUrlEmpresa: signupUrlInfluencer);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
    },
    body: JSON.stringify({ 
      nuevoEmail: email,
      password: password, 
      passwordCheck: password
    
    }),
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      //  console.log(res);
       return res;
    })
    .catch((error) => { 
      throw error })
}

// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* signupFlow (action) {
  try {
    const { email, password, tipo } = action
    // console.log(tipo);
    // pulls "calls" to our signupApi with our email and password
    // from our dispatched signup action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield call(signupApi, email, password, tipo)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type SIGNUP_SUCCESS with
    // the successful response.
    yield put({ type: SIGNUP_SUCCESS, response })
    
    if (response.hasOwnProperty('token')){
      //paso el token del login
      const token=response.token;
      yield put(setClient({token: token }))
      localStorage.setItem('token', JSON.stringify({token: token }))
    }
    /*if (response.estado==="ok" && response.pendiente_verificacion==="true"){
      yield put(push('/verificacion'));
    }*/
    yield put(push('/account-details'));


  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: SIGNUP_ERROR, error })
  }
}

// Watches for the SIGNUP_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* signupWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(SIGNUP_REQUESTING, signupFlow)
}

export default signupWatcher
