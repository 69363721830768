import { Form, Field, option } from 'react-final-form';
import updateAccountDetailsRequest from '../../datoscuenta/actions';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';

const ColumnaDatos = styled.div`
  width: calc(33.3% - 45px);
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  @media (max-width: 500px) {
    width: calc(90% - 45px);
  }
`;

const DatosCuentaForm = ({tipo}) => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
   
    const onSubmit = (values) =>{
      //values = {...values, tipo: tipo};
      dispatch(updateAccountDetailsRequest(values,client));
    };
    const validate = () =>{

    };

    return ( 
      <div className="col-md-12 mauto">
        <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form-datos-cuenta">
            <ColumnaDatos>
            <Field
            name="nombre"
            type="text"
            id="nombre"
            className="input_text_1 w100"
            component="input"
            placeholder="Nombre"
          />

<Field
            name="apellidos"
            type="text"
            id="apellidos"
            className="input_text_1 w100"
            component="input"
            placeholder="Apellidos"
          />

<Field
            name="nif"
            type="text"
            id="nif"
            className="input_text_1 w100"
            component="input"
            placeholder="NIF"
          />
          <Field
            name="cargo"
            type="text"
            id="cargo"
            className="input_text_1 w100"
            component="input"
            placeholder="Cargo"
          />

<Field
            name="telefono"
            type="text"
            id="telefono"
            className="input_text_1 w100"
            component="input"
            placeholder="Teléfono"
          />


          </ColumnaDatos>
           <ColumnaDatos>
           {client.decode.roles.includes('ROLE_INFLUENCER') ? null :
        
            <Field
            name="razon_social"
            type="text"
            id="razon_social"
            className="input_text_1 w100"
            component="input"
            placeholder="Razón social"
          />
        }
<Field
            name="cif"
            type="text"
            id="cif"
            className="input_text_1 w100"
            component="input"
            placeholder="CIF"
          />
 {client.decode.roles.includes('ROLE_INFLUENCER') ? null :
        
<Field
            name="numero_empleados"
            id="nif"
            className="input_text_1 w100"
            component="select"
            placeholder="Número de empleados" >
            <option>Número de empleados</option>
                <option value="1">1-50</option>
                <option value="2">51-100</option>
          </Field>
        }
          </ColumnaDatos>
          <ColumnaDatos>


         
            <Field
            name="direccion"
            type="text"
            id="direccion"
            className="input_text_1 w100"
            component="input"
            placeholder= {client.decode.roles.includes('ROLE_INFLUENCER') ? "Dirección de facturación" :"Dirección de la empresa"}
          />

<Field
            name="ciudad"
            type="text"
            id="ciudad"
            className="input_text_1 w100"
            component="input"
            placeholder="Ciudad"
          />

<Field
            name="provincia"
            type="text"
            id="provincia"
            className="input_text_1 w100"
            component="input"
            placeholder="Provincia"
          />

<Field
            name="pais"
            type="text"
            id="pais"
            className="input_text_1 w100"
            component="input"
            placeholder="Pais"
          />
<div className="checbox_container">
<Field
            name="acepto"
            type="checkbox"
            id="acepto"
            className=""
            component="input"
          />
<label>He leido y acepto la <strong>política de privacidad</strong></label> 
</div>         

          
<button action="submit" className="submit_verder w100">Enviar</button>
          
</ColumnaDatos>
          </form>
        
        )}
      />
      </div>
    )
}

export default DatosCuentaForm;