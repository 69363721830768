import { 
  UPDATEACCOUNTDETAILS_REQUESTING,
  GET_ACCOUNT_DETAILS_REQUESTING
 } from './constants'

export function updateAccountDetailsRequest (datos, client) {
  return {
    type: UPDATEACCOUNTDETAILS_REQUESTING,
    datos,
    client
  }
}

export function getAccountDetailsRequest(client) {
  return {
    type: GET_ACCOUNT_DETAILS_REQUESTING,
    client,
  }
}

export default updateAccountDetailsRequest;
