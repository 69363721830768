import {
  SINGLE_CAMPAIGN_REQUESTING,
  SINGLE_CAMPAIGN_MENTIONED_REQUESTING,
  SINGLE_CAMPAIGN_USERNAMES_REQUESTING,
  DELETE_MENTIONED_REQUESTING,
  SINGLE_CAMPAIGN_STORIES_REQUESTING,
  DATOS_GRAFICA_SPLINE_REQUESTING,
  FILTROS_APPLY,
  DATOS_GRAFICA_DONUT_REQUESTING,
  FILTROS_RESULT_UPDATE_VALUE,
  SINGLE_CAMPAIGN_ADDINFLUENCER,
  SINGLE_CAMPAIGN_DELETE_INFLUENCER,
  SINGLE_CAMPAIGN_ADD_HASHTAG,
  SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST,
  CAMPAING_INFORME_REQUESTING,
  CAMPAING_INFORME_SUCCESS
} from './constants'

export function singleCampaign(client, idCamp, filtro=null, page=null){
 return {
    type: SINGLE_CAMPAIGN_REQUESTING,
    payload: {
      client: client,
      idCamp: idCamp,
      filtro: filtro,
      "page": page,
    }
  }
}
export function singleCampaignMentionedMedia(client, idCamp, page=null, filtro={}){
  return {
     type: SINGLE_CAMPAIGN_MENTIONED_REQUESTING,
     payload: {
       client: client,
       idCamp: idCamp,
       "page": page,
       filtro: filtro
     }
   }
 }

 export function singleCampaignUsernames(client, idCamp, page=null, filtro={}){
   return {
    type: SINGLE_CAMPAIGN_USERNAMES_REQUESTING,
    payload: {
      client: client,
      idCamp: idCamp,
      "page": page,
      filtro: filtro
    }
   };
 };

 export function singleCampaignStories(client, idCamp, page=null, filtro={}){
  return {
   type: SINGLE_CAMPAIGN_STORIES_REQUESTING,
   payload: {
     client: client,
     idCamp: idCamp,
     "page": page,
     filtro: filtro
   }
  };
};

 export function deleteMentionedRequesting(client, idPost){
   return {
     type: DELETE_MENTIONED_REQUESTING,
     payload: {
       client: client,
       idPost: idPost,
     }
   };
 };

 export function getDataGraficaSpline(client, idPost, filtro={}){
  return {
    type: DATOS_GRAFICA_SPLINE_REQUESTING,
    payload: {
      client: client,
      idPost: idPost,
      filtro: filtro
    }
  };
};

export function aplicarFiltros(client, idCamp, values){
  return {
     type: FILTROS_APPLY,
     payload: {
       client: client,
       idCamp: idCamp,
      values: values
     }
   }
 }
export function getDataGraficaDonut(client, idPost){
  return {
    type: DATOS_GRAFICA_DONUT_REQUESTING,
    payload: {
      client: client,
      idPost: idPost,
    }
  };
};

export function updateValorCampRespuestaFiltro(campo,valor){
  return {
    type: FILTROS_RESULT_UPDATE_VALUE,
    payload: {
      campo: campo,
      valor: valor,
    }
  };
};

export function addInfluencer(influencer, idCamp, client){
  return {
    type: SINGLE_CAMPAIGN_ADDINFLUENCER,
    payload: {
      influencer: influencer,
      campaing: idCamp,
      client: client
    }
  };
};
export function deleteInfluencer(influencer, idCamp, client){
  return {
    type: SINGLE_CAMPAIGN_DELETE_INFLUENCER,
    payload: {
      influencer: influencer,
      campaing: idCamp,
      client: client
    }
  };
};
export function addHashtag(hashtag, idCamp, client){
  return {
    type: SINGLE_CAMPAIGN_ADD_HASHTAG,
    payload: {
      hashtag: hashtag,
      campaing: idCamp,
      client: client
    }
  };
};


export function obtenerMencionesDelUsuario(client, idCamp, page=null, filtro={}, username){
  return {
     type: SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST,
     payload: {
       client: client,
       idCamp: idCamp,
       "page": page,
       filtro: filtro,
       username: username
     }
   }
 }

 export function getInformeInit(tipo){
  return {
    type: CAMPAING_INFORME_REQUESTING,
    payload: tipo
  };
};

export function getInformeFin(){
  return {
    type: CAMPAING_INFORME_SUCCESS,
  };
};



// Since it's the only one here
export default singleCampaign
